import { Widgets } from "./Widgets";

export class SMSSender extends Widgets {
    constructor(selector) {
        super(selector);
        this.send_button = this.element.querySelector(".send-sms-button");
        this.user = this.element.querySelector('input[name="user"]');
        this.cellphone = this.element.querySelector('input[name="to"]');
        this.message = this.element.querySelector('textarea[name="message"]');
        this.error_message = this.element.querySelector(".error-message");
        this.user.addEventListener("blur", e => {
            // this.preview();
        });
        this.cellphone.addEventListener("blur", e => {
            // this.preview();
        });
        this.cellphone.addEventListener("keyup", e => {
            this.error_message.innerHTML = "";
        });

        this.send_button.addEventListener("click", e => {
            this.send_button.classList.add("inactive");
            let data = {};
            data.url = this.parent.currentLocation(this.parent.router.root);
            let pass = true;
            let errors = [];
            this.element.querySelectorAll(".form-group .form-field").forEach(elmn => {
                if (elmn.value == "" && elmn.attributes.required) {
                    errors.push({
                        element: elmn,
                        error: this.parent.translate("widget/share_topic/messages/cellphone_required")
                    })
                    pass = false;
                    return;
                }
                // console.log(elmn);
                if (elmn.attributes.masked) {
                    data[elmn.name] = $(elmn).cleanVal();
                    if (elmn.name === "to") {
                        if (data[elmn.name].length < 11) {
                            errors.push({
                                element: elmn,
                                error: this.parent.translate("widget/share_topic/messages/cellphone_incomplete")
                            })
                            pass = false;
                            return;
                        }
                    }
                } else {
                    data[elmn.name] = elmn.value.trim();
                }
            });
            if (pass) {
                this.send(data)
            } else {
                console.log(errors);
                this.error_message.innerHTML = errors[0].error;
                this.send_button.classList.remove("inactive");
            }
        });
    }
    init() {
        let event = new Event("SMSSenderInitiated");
        event.widget = this;
        this.parent.element.dispatchEvent(event);
    }
    async preview() {
        if (!this.parent.send_content_preview) {
            this.parent.send_content_preview = await this.parent.call("send/preview");
        }

        if (this.parent.send_content_preview) {
            let message = this.parent.send_content_preview.find(item => {
                return item.type == "SMS";
            }).content;
            // console.log(message);

            message = message.replace("{name}", this.user.value);
            message = message.replace("{link}", this.parent.settings.environment.live + this.parent.currentLocation(this.parent.router.root));
            this.message.value = message;
        }
    }
    async send(params) {
        this.parent.loading.start();

        let data = {
            "to": params.to,
            "name": params.user,
            "link": this.parent.settings.environment.live + params.url
        }

        let sendsms = await fetch(this.parent.settings.smssender.endpoint, {
            method: "POST",
            // mode: "no-cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), 
        });
        let response = await sendsms.json();

        // let response = await this.parent.post(this.parent.settings.smssender.endpoint, ()=>{
        //     try {
        //         window.alert(this.parent.translate("widget/share_topic/messages/sms_sent"));
        //         this.parent.loading.stop();
        //     } catch (error) {
        //         console.log(error);
        //     }
        //     this.send_button.classList.remove("inactive");

        // }, "POST", data, null, true);

        try {
            window.alert(this.parent.translate("widget/share_topic/messages/sms_sent"));
            this.cellphone.value = "";
            this.parent.loading.stop();
        } catch (error) {
            console.log(error);
        }
        var _that = this;
        setTimeout(()=>{
            _that.send_button.classList.remove("inactive");
        }, 1000)
        return response;
    }
}